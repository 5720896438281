var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.isAdd
        ? _c("Title", { attrs: { name: "添加产品" } })
        : _c("Title", { attrs: { name: "修改产品" } }),
      _vm.goods.approveStatus === 2
        ? _c("el-alert", {
            attrs: {
              title: _vm.goods.approveMsg,
              type: "error",
              "show-icon": "",
            },
          })
        : _vm._e(),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("南海灵通铝锭价")]),
          _c(
            "el-form",
            {
              ref: "goods",
              attrs: {
                rules: _vm.rules,
                model: _vm.goods,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "使用南海灵通铝锭价（含票）",
                            "label-width": "220px",
                            prop: "isOnSale",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.checkboxChange },
                              model: {
                                value: _vm.goods.isLvdingPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goods, "isLvdingPrice", $$v)
                                },
                                expression: "goods.isLvdingPrice",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("使用"),
                              ]),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("不使用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.goods.isLvdingPrice
                              ? "南海灵通铝锭价"
                              : "铝锭价格",
                            "label-width": _vm.goods.isLvdingPrice
                              ? "120px"
                              : "100px",
                            prop: "counterPrice",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                readonly: _vm.goods.isLvdingPrice,
                                placeholder: "0",
                              },
                              model: {
                                value: _vm.goods.counterPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goods, "counterPrice", $$v)
                                },
                                expression: "goods.counterPrice",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元/吨"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.lvDingInfo != null && _vm.goods.isLvdingPrice
                ? _c(
                    "el-row",
                    { attrs: { gutter: 30 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "铝锭价公布日期",
                                "label-width": "140px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.lvDingInfo.publishDay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.lvDingInfo, "publishDay", $$v)
                                  },
                                  expression: "lvDingInfo.publishDay",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品介绍")]),
          _c(
            "el-form",
            {
              ref: "goods",
              attrs: {
                rules: _vm.rules,
                model: _vm.goods,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品编号", prop: "goodsSn" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.goods.goodsSn,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "goodsSn", $$v)
                              },
                              expression: "goods.goodsSn",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品名称", prop: "name" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.goods.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "name", $$v)
                              },
                              expression: "goods.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "加工费", prop: "processCharge" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "0" },
                              model: {
                                value: _vm.goods.processCharge,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goods, "processCharge", $$v)
                                },
                                expression: "goods.processCharge",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品价格", prop: "retailPrice" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "铝锭价格+加工费" },
                              on: { change: _vm.emptyThePrice },
                              model: {
                                value: _vm.goods.retailPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goods, "retailPrice", $$v)
                                },
                                expression: "goods.retailPrice",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "制造厂商", prop: "manufacturer" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "制造厂商" },
                            model: {
                              value: _vm.goods.manufacturer,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "manufacturer", $$v)
                              },
                              expression: "goods.manufacturer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "材质", prop: "material" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.goods.material,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "material", $$v)
                              },
                              expression: "goods.material",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品重量", prop: "weight" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "0" },
                              model: {
                                value: _vm.goods.weight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goods, "weight", $$v)
                                },
                                expression: "goods.weight",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("吨"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "起订量", prop: "minimumPurchase" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "0" },
                            model: {
                              value: _vm.goods.minimumPurchase,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "minimumPurchase", $$v)
                              },
                              expression: "goods.minimumPurchase",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品库存", prop: "inventory" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "在没有规格时，请填写这一列",
                            },
                            model: {
                              value: _vm.goods.inventory,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "inventory", $$v)
                              },
                              expression: "goods.inventory",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库名称", prop: "entrepot" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "所在仓库" },
                            model: {
                              value: _vm.goods.entrepot,
                              callback: function ($$v) {
                                _vm.$set(_vm.goods, "entrepot", $$v)
                              },
                              expression: "goods.entrepot",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品单位", prop: "unit" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "331.9px" },
                              attrs: {
                                placeholder: "请选择单位",
                                "allow-create": "",
                                filterable: "",
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.goods.unit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goods, "unit", $$v)
                                },
                                expression: "goods.unit",
                              },
                            },
                            _vm._l(_vm.unitList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属分类", prop: "categoryIds" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.categoryList,
                              "expand-trigger": "hover",
                            },
                            on: { change: _vm.handleCategoryChange },
                            model: {
                              value: _vm.categoryIds,
                              callback: function ($$v) {
                                _vm.categoryIds = $$v
                              },
                              expression: "categoryIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "地区", prop: "regionIds" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              options: _vm.regionList,
                              "expand-trigger": "hover",
                            },
                            on: { change: _vm.handleRegionChange },
                            model: {
                              value: _vm.regionIds,
                              callback: function ($$v) {
                                _vm.regionIds = $$v
                              },
                              expression: "regionIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否在售", prop: "isOnSale" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.goods.isOnSale,
                                callback: function ($$v) {
                                  _vm.$set(_vm.goods, "isOnSale", $$v)
                                },
                                expression: "goods.isOnSale",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("在售"),
                              ]),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("未售"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片", prop: "picUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        headers: _vm.headers,
                        "on-success": _vm.uploadPicUrl,
                        accept: ".jpg,.jpeg,.png,.gif",
                      },
                    },
                    [
                      _vm.goods.picUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.goods.picUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "宣传画廊" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadPath,
                        limit: 5,
                        headers: _vm.headers,
                        "file-list": _vm.galleryFileList,
                        "on-exceed": _vm.uploadOverrun,
                        "on-success": _vm.handleGalleryUrl,
                        "on-remove": _vm.handleRemove,
                        multiple: "",
                        accept: ".jpg,.jpeg,.png,.gif",
                        "list-type": "picture-card",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品简介" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goods.brief,
                      callback: function ($$v) {
                        _vm.$set(_vm.goods, "brief", $$v)
                      },
                      expression: "goods.brief",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品详细介绍" } },
                [
                  _c("tinymce-editor", {
                    attrs: { value: _vm.goods.detail },
                    on: {
                      input: function ($event) {
                        _vm.goods.detail = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "h3",
            { staticStyle: { "line-height": "30px", "margin-top": "0" } },
            [
              _vm._v(" 商品规格 "),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", plain: true, type: "primary" },
                  on: { click: _vm.handleSpecificationShow },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm.tableShow
            ? [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.products,
                      "header-cell-style": {
                        background: "#f5f5f5",
                        "font-weight": 500,
                        color: "#666",
                        padding: "12 5px",
                      },
                    },
                  },
                  [
                    _vm._l(5, function (item) {
                      return [
                        _vm.customize[`${item - 1}`]
                          ? _c("el-table-column", {
                              key: item,
                              attrs: {
                                property: `value${item - 1}`,
                                label: _vm.customize[`${item - 1}`].title,
                              },
                            })
                          : _vm._e(),
                      ]
                    }),
                    _c("el-table-column", {
                      attrs: { property: "url", label: "图片" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.url
                                  ? _c("img", {
                                      attrs: {
                                        src: scope.row.url,
                                        width: "40",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1742217347
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { property: "price", label: "价格" },
                    }),
                    _c("el-table-column", {
                      attrs: { property: "number", label: "库存" },
                    }),
                    !_vm.multipleSpec
                      ? _c("el-table-column", {
                          attrs: {
                            fixed: "right",
                            align: "center",
                            label: "操作",
                            width: "250",
                            "class-name": "small-padding fixed-width",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "danger", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSpecificationDelete(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2506674061
                          ),
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.specVisiable, title: "设置规格" },
              on: {
                "update:visible": function ($event) {
                  _vm.specVisiable = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "specForm",
                  staticStyle: { width: "400px", "margin-left": "10px" },
                  attrs: {
                    rules: _vm.rulesSpecForm,
                    model: _vm.specForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "70px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "价格", prop: "price", size: "mini" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.specForm, "price", $$v)
                          },
                          expression: "specForm.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "库存量", prop: "number", size: "mini" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.specForm, "number", $$v)
                          },
                          expression: "specForm.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格图片", prop: "url", size: "mini" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.uploadPath,
                            "show-file-list": false,
                            headers: _vm.headers,
                            "on-success": _vm.uploadSpecPicUrl,
                            accept: ".jpg,.jpeg,.png,.gif",
                          },
                        },
                        [
                          _vm.specForm.url
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.specForm.url },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { widht: "400px", "margin-bottom": "10px" },
                      attrs: { icon: "el-icon-plus" },
                      on: { click: _vm.addInput },
                    },
                    [_vm._v("添加自定义规格")]
                  ),
                  _vm._l(_vm.input, function (items, index) {
                    return _c(
                      "div",
                      { key: index, staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            readonly: items.readonly,
                            placeholder: "规格，如颜色",
                            size: "mini",
                          },
                          model: {
                            value: items.title,
                            callback: function ($$v) {
                              _vm.$set(items, "title", $$v)
                            },
                            expression: "items.title",
                          },
                        }),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v(":"),
                        ]),
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            placeholder: "规格值，如红色",
                            size: "mini",
                          },
                          model: {
                            value: items.data,
                            callback: function ($$v) {
                              _vm.$set(items, "data", $$v)
                            },
                            expression: "items.data",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.specVisiable = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleSpecificationAdd("specForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "h3",
            { staticStyle: { "line-height": "30px", "margin-top": "0" } },
            [
              _vm._v(" 商品参数 "),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", plain: true, type: "primary" },
                  on: { click: _vm.handleAttributeShow },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.attributes } },
            [
              _c("el-table-column", {
                attrs: { property: "attribute", label: "商品参数名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "value", label: "商品参数值" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "100",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAttributeDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.attributeVisiable,
                width: "700px",
                title: "设置商品参数",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.attributeVisiable = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "attributeForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.attributeForm,
                    rules: _vm.rulesAttribute,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品参数名称",
                        size: "mini",
                        prop: "attribute",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attributeForm.attribute,
                          callback: function ($$v) {
                            _vm.$set(_vm.attributeForm, "attribute", $$v)
                          },
                          expression: "attributeForm.attribute",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品参数值",
                        size: "mini",
                        prop: "value",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attributeForm.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.attributeForm, "value", $$v)
                          },
                          expression: "attributeForm.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.attributeVisiable = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAttributeAdd("attributeForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "op-container" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _vm.isAdd
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlePublish("goods")
                    },
                  },
                },
                [_vm._v("上架")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleUpdateGoods("goods")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }