<template>
  <div class="app-container">
    <Title v-if="isAdd" name='添加产品'></Title>
    <Title v-else name='修改产品'></Title>
    <el-alert
            :title="goods.approveMsg"
            type="error"
            show-icon
            v-if="goods.approveStatus===2"
    >
    </el-alert>

    <el-card class="box-card">
      <h3>南海灵通铝锭价</h3>
      <el-form ref="goods" :rules="rules" :model="goods" label-width="100px">
        <el-row :gutter="30" >
          <el-col :span="11">
            <el-form-item label="使用南海灵通铝锭价（含票）" label-width="220px" prop="isOnSale">
              <el-radio-group v-model="goods.isLvdingPrice" @change="checkboxChange">
                <el-radio :label=true>使用</el-radio>
                <el-radio :label=false>不使用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item :label="goods.isLvdingPrice?'南海灵通铝锭价':'铝锭价格'"
                          :label-width="goods.isLvdingPrice?'120px':'100px'"
                          prop="counterPrice">
              <el-input :readonly="goods.isLvdingPrice" v-model="goods.counterPrice" placeholder="0">
                <template slot="append">元/吨</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30" v-if="lvDingInfo!=null&&goods.isLvdingPrice">
          <el-col :span="11">
            <el-form-item label="铝锭价公布日期" label-width="140px">
              <el-input v-model="lvDingInfo.publishDay" readonly>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <h3>商品介绍</h3>
      <el-form ref="goods" :rules="rules" :model="goods" label-width="100px">

        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="商品编号" prop="goodsSn">
              <el-input v-model="goods.goodsSn" />
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="商品名称" prop="name">
              <el-input v-model="goods.name"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="加工费" prop="processCharge">
              <el-input v-model="goods.processCharge" placeholder="0">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="商品价格" prop="retailPrice">
              <el-input v-model="goods.retailPrice" placeholder="铝锭价格+加工费" @change="emptyThePrice">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="制造厂商" prop="manufacturer">
              <el-input v-model="goods.manufacturer" placeholder="制造厂商">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="材质" prop="material">
              <el-input v-model="goods.material" placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="商品重量" prop="weight">
              <el-input v-model="goods.weight" placeholder="0">
                <template slot="append">吨</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="起订量" prop="minimumPurchase">
              <el-input v-model="goods.minimumPurchase" placeholder="0">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="商品库存" prop="inventory">
              <el-input v-model="goods.inventory" placeholder="在没有规格时，请填写这一列">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="仓库名称" prop="entrepot">
              <el-input v-model="goods.entrepot" placeholder="所在仓库">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="商品单位" prop="unit">
              <el-select v-model="goods.unit"
                         placeholder="请选择单位"
                         allow-create
                         filterable
                         style="width: 331.9px;"
                         default-first-option>
                <el-option v-for="(item, index) in unitList"
                           :key="index"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
              <!-- <el-input v-model="goods.unit" placeholder="件 / 个 / 盒"/> -->
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="所属分类" prop="categoryIds">
              <el-cascader v-model="categoryIds" :options="categoryList"
                           expand-trigger="hover" @change="handleCategoryChange"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="11">
            <el-form-item label="地区" prop="regionIds">
              <el-cascader v-model="regionIds" :options="regionList"
                           expand-trigger="hover" @change="handleRegionChange"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="是否在售" prop="isOnSale">
              <el-radio-group v-model="goods.isOnSale">
                <el-radio :label="true">在售</el-radio>
                <el-radio :label="false">未售</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!--<el-form-item label="口径" prop="caliber">-->
        <!--  <el-input v-model="goods.caliber" placeholder="0">-->
        <!--  </el-input>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="宽度" prop="caliber">-->
        <!--  <el-input v-model="goods.width" placeholder="0">-->
        <!--  </el-input>-->
        <!--</el-form-item>-->

        <!--<el-form-item label="是否新品" prop="isNew">-->
        <!--  <el-radio-group v-model="goods.isNew">-->
        <!--    <el-radio :label="true">新品</el-radio>-->
        <!--    <el-radio :label="false">非新品</el-radio>-->
        <!--  </el-radio-group>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="是否热卖" prop="isHot">-->
        <!--  <el-radio-group v-model="goods.isHot">-->
        <!--    <el-radio :label="false">普通</el-radio>-->
        <!--    <el-radio :label="true">热卖</el-radio>-->
        <!--  </el-radio-group>-->
        <!--</el-form-item>-->


        <!--<el-form-item label="是否现货" prop="existing">-->
        <!--  <el-radio-group v-model="goods.existing">-->
        <!--    <el-radio :label="0">现货</el-radio>-->
        <!--    <el-radio :label="1">预售</el-radio>-->
        <!--  </el-radio-group>-->
        <!--</el-form-item>-->
        <el-form-item label="商品图片" prop="picUrl">
          <el-upload
            :action="uploadPath"
            :show-file-list="false"
            :headers="headers"
            :on-success="uploadPicUrl"
            class="avatar-uploader"
            accept=".jpg,.jpeg,.png,.gif">
            <img v-if="goods.picUrl" :src="goods.picUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"/>
          </el-upload>
        </el-form-item>

        <el-form-item label="宣传画廊">
          <el-upload
            :action="uploadPath"
            :limit="5"
            :headers="headers"
            :file-list="galleryFileList"
            :on-exceed="uploadOverrun"
            :on-success="handleGalleryUrl"
            :on-remove="handleRemove"
            multiple
            accept=".jpg,.jpeg,.png,.gif"
            list-type="picture-card">
            <i class="el-icon-plus"/>
          </el-upload>
        </el-form-item>



        <!--<el-form-item label="关键字">-->
        <!--  <el-tag v-for="tag in keywords" :key="tag" closable type="primary" @close="handleClose(tag)">-->
        <!--    {{ tag }}-->
        <!--  </el-tag>-->
        <!--  <el-input-->
        <!--    v-if="newKeywordVisible"-->
        <!--    ref="newKeywordInput"-->
        <!--    v-model="newKeyword"-->
        <!--    class="input-new-keyword"-->
        <!--    size="small"-->
        <!--    @keyup.enter.native="handleInputConfirm"-->
        <!--    @blur="handleInputConfirm"/>-->
        <!--  <el-button v-else class="button-new-keyword" size="small" type="primary" @click="showInput">+ 增加</el-button>-->
        <!--</el-form-item>-->

        <el-form-item label="商品简介">
          <el-input v-model="goods.brief"/>
        </el-form-item>
        <el-form-item label="商品详细介绍">
          <tinymce-editor :value="goods.detail" @input="goods.detail = $event"></tinymce-editor>
        </el-form-item>
      </el-form>
    </el-card>
    <!--规格-->
    <el-card class="box-card">
      <h3 style="line-height:30px;margin-top:0">
        商品规格
        <el-button style="float:right" size="mini" :plain="true" type="primary" @click="handleSpecificationShow">添加</el-button>
      </h3>
      <!--<el-row :gutter="20" type="flex" align="middle" style="padding:20px 0;">-->
      <!--  <el-col :span="10">-->
      <!--    <el-radio-group v-model="multipleSpec" @change="specChanged">-->
      <!--      <el-radio-button :label="false">默认标准规格</el-radio-button>-->
      <!--      <el-radio-button :label="true">多规格支持</el-radio-button>-->
      <!--    </el-radio-group>-->
      <!--  </el-col>-->
        <!-- <el-col style="width:100%;text-align:right;margin-bottom:10px" :span="10">

        </el-col> -->
      <!--</el-row>-->
      <template v-if="tableShow">
      <el-table :data="products"  :header-cell-style="{background:'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px'}">
        <template v-for="item in 5" >
          <!--<el-table-column  v-if="customize[`data${item-1}`]" :key="item" :property="`data${item-1}value`" :label="customize[`data${item-1}`].title"/>-->
          <el-table-column  v-if="customize[`${item-1}`]" :key="item" :property="`value${item-1}`" :label="customize[`${item-1}`].title"/>
          <!--<el-table-column v-if="products.specification[item-1]" :key="item" :property="products.specification[item-1].value" :label="products.specification[item-1].specification"/>-->
        </template>
        <el-table-column property="url" label="图片">
          <template slot-scope="scope">
            <img v-if="scope.row.url" :src="scope.row.url" width="40">
          </template>
        </el-table-column>
        <el-table-column property="price" label="价格"/>
        <el-table-column property="number" label="库存"/>
        <el-table-column
          v-if="!multipleSpec"
          fixed="right"
          align="center"
          label="操作"
          width="250"
          class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="handleSpecificationDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      </template>

      <el-dialog :visible.sync="specVisiable" title="设置规格">
        <el-form
          ref="specForm"
          :rules="rulesSpecForm"
          :model="specForm"
          status-icon
          label-position="left"
          label-width="70px"
          style="width: 400px; margin-left:10px;">
          <el-form-item label="价格" prop="price" size="mini">
            <el-input v-model="specForm.price" />
          </el-form-item>
          <el-form-item label="库存量" prop="number" size="mini">
            <el-input v-model="specForm.number"/>
          </el-form-item>
          <el-form-item label="规格图片" prop="url" size="mini">
            <el-upload
              :action="uploadPath"
              :show-file-list="false"
              :headers="headers"
              :on-success="uploadSpecPicUrl"
              class="avatar-uploader"
              accept=".jpg,.jpeg,.png,.gif">
              <img v-if="specForm.url" :src="specForm.url" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"/>
            </el-upload>
          </el-form-item>
        </el-form>
        <div>
          <el-button style="widht:400px;margin-bottom:10px" @click='addInput' icon='el-icon-plus'>添加自定义规格</el-button>
          <div v-for="(items,index) in input" :key="index" style="margin-bottom:10px">
            <el-input v-model="items.title" :readonly="items.readonly" placeholder="规格，如颜色" size="mini" style="width:120px;"></el-input>
            <span style="margin:0 5px">:</span>
            <el-input v-model="items.data" placeholder="规格值，如红色" size="mini" style="width:400px;"></el-input>
            <!--<i class="el-icon-error" style="color:red;font-size:16px;margin-left:10px;cursor:pointer" @click="delCol(items,index)"></i>-->
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="specVisiable = false">取消</el-button>
          <el-button type="primary" @click="handleSpecificationAdd('specForm')">确定</el-button>
        </div>
      </el-dialog>
    </el-card>
    <!--商品属性-->
    <el-card class="box-card">
      <h3 style="line-height:30px;margin-top:0">
        商品参数
        <el-button style="float:right" size="mini" :plain="true" type="primary" @click="handleAttributeShow">添加</el-button>
      </h3>
      <el-table :data="attributes">
        <el-table-column property="attribute" label="商品参数名称"/>
        <el-table-column property="value" label="商品参数值"/>
        <el-table-column align="center" label="操作" width="100" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="handleAttributeDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog :visible.sync="attributeVisiable" width="700px" title="设置商品参数">
        <el-form
          ref="attributeForm"
          :model="attributeForm"
          :rules="rulesAttribute"
          status-icon
          label-position="left"
          label-width="130px"
          style="width: 400px; margin-left:50px;">
          <el-form-item label="商品参数名称" size="mini" prop="attribute">
            <el-input v-model="attributeForm.attribute"/>
          </el-form-item>
          <el-form-item label="商品参数值" size="mini" prop="value">
            <el-input v-model="attributeForm.value"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="attributeVisiable = false">取消</el-button>
          <el-button type="primary" @click="handleAttributeAdd('attributeForm')">确定</el-button>
        </div>
      </el-dialog>
    </el-card>

    <div class="op-container">
      <el-button @click="handleCancel">取消</el-button>
      <el-button v-if="isAdd" type="primary" @click="handlePublish('goods')">上架</el-button>
      <el-button v-else type="primary" @click="handleUpdateGoods('goods')">修改</el-button>
    </div>
  </div>
</template>

<script>
import Title from '../../component/Title';
import tinymceEditor from '@/components/tinymceEditor.vue';
import {categoryAll, uploadPath, addGoods, goodsDetail, modifyGoods,regionProvinceAndCity,newestLvdingPrice} from '../../api';
import * as MessageBox from "element-ui";

export default {
  name: 'GoodsCreate',
  components: {
    Title,
    'tinymce-editor': tinymceEditor
  },
  data() {
    const isPrice = (rule, value, callback) => {
      const age= /^(([0-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/
      if(value==undefined || value==null || value==''){
        callback()
      }
      if (!age.test(value)) {
        callback(new Error('请输入正确的价格,只能有两位小数'))
      }else{
        callback()
      }
    };
    const isNum = (rule, value, callback) => {
      const age= /^(([1-9]*\d*)|(0{1}))(\.\d*)?$/
      if (!age.test(value)) {
        callback(new Error('请输入正确的数字'))
      }else{
        callback()
      }
    };
    const isRegionNotNULL = (rule, value, callback) => {
      // const age= /^[0-9]*[.]?[0-9]{2}$/
      if (!this.regionIds||this.regionIds.length<=0) {
        callback(new Error('请选择地区'))
      }else{
        callback()
      }
    };
    const isCategoryNotNULL = (rule, value, callback) => {
      // const age= /^[0-9]*[.]?[0-9]{2}$/
      if (!this.categoryIds||this.categoryIds.length<=0) {
        callback(new Error('请选择所属分类'))
      }else{
        callback()
      }
    };
    const inventory=(rule,value,callback)=>{
      const format= /^\d{1,8}$/
      const num=/^\d{0,8}$/
      if(this.specifications.length<=0){
        if(!format.test(value)) {
          callback(new Error('无规格时，请填写正确库存'));
        }else{
          callback()
        }
      }else{
        if(value!==undefined&&!num.test(value)) {
          callback(new Error('请填写数字'));
        }else{
          callback()
        }
      }
    }
    return {
      unitList: ['吨', '公斤', '克', '件', '套', '根', '个'],
      input:[],
      customize:[],
      cache: [],
      tableShow:false,
      isAdd:true,
      marking:0,
      marking2:0,
      uploadPath,
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      galleryFileList: [],
      categoryIds: [],
      categoryList: [],
      regionIds:[],
      regionList:[],
      brandList: [],
      goods: {
        goodsSn:'',
        name:'',
        counterPrice:undefined,
        retailPrice:undefined,
        picUrl: '',
        gallery: [],
        manufacturer:'',
        existing:'',
        regionId:undefined,
        regionName:'',
        entrepot:'',
        material:'',
        weight:'',
        units:'吨',
        minimumPurchase:1,
        caliber:'',
        width:'',
        processCharge:undefined,
        isLvdingPrice:false,
      },
      specVisiable: false,
      specForm: {  number: '', url: '',price: '' },
      multipleSpec: false,
      specifications: [{ specification: '', value: '', picUrl: '' }],
      productVisiable: false,
      productForm: { id: 0, specification: [], price: 0.00, number: 0, url: '' },
      products: [{ id: 0, specifications: [], price: 0.00, number: 0, url: '' }],
      attributeVisiable: false,
      attributeForm: { attribute: '', value: '' },
      attributes: [],
      //南海灵通铝价
      lvDingInfo:null,
      //效验
      rules: {
        goodsSn: [{ required: true, message: '商品编号不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '商品名称不能为空', trigger: 'blur' }],
        counterPrice: [{ validator: isPrice, trigger: ['blur','change'] }],
        processCharge: [{ validator: isPrice, trigger: ['blur','change'] }],
        retailPrice: [{ required: true, message: '商品价格不能为空', trigger: ['blur','change']},
          { validator: isPrice, trigger: ['blur','change'] }],
        entrepot: [{ required: true, message: '仓库名称不能为空', trigger: 'blur' }],
        manufacturer: [{ required: true, message: '制造厂商不能为空', trigger: 'blur' }],
        material: [{ required: true, message: '材质不能为空', trigger: 'blur' }],
        // weight: [{ required: true, message: '商品重量不能为空', trigger: 'blur' },
        //   {validator:isNum, trigger: 'blur'}],
        inventory: [{validator:inventory, trigger: 'blur'}],
        // isNew: [{ required: true, message: '请选择是否新品', trigger: 'blur' }],
        // isHot: [{ required: true, message: '请选择是否热卖', trigger: 'blur' }],
        isOnSale: [{ required: true, message: '请选择是否在售', trigger: 'blur' }],
        // existing: [{ required: true, message: '请选择是否现货', trigger: 'blur' }],
        picUrl: [{ required: true, message: '请上传商品图片', trigger: ['blur','change'] }],
        minimumPurchase: [{ required: true, message: '最低购买量不能为空', trigger: 'blur' },
          {validator:isNum,trigger: 'blur'}],
        unit: [{ required: true, message: '商品单位不能为空', trigger: 'blur' }],
        categoryIds: [{required: true,validator:isCategoryNotNULL,trigger:['blur','change']}],
        regionIds: [{ required: true,validator:isRegionNotNULL,trigger:['blur','change'] }],
      },
      rulesSpecForm: {
        // material: [ { required: true, message: '材质', trigger: 'blur' } ],
        // size: [ { required: true, message: '尺寸不能为空', trigger: 'blur' } ],
        number: [ { required: true, message: '库存量不能为空', trigger: 'blur' },
          { required: true,validator:isNum,trigger:['blur','change']} ],
        price: [ { required: true, message: '价格不能为空', trigger: 'blur' },
          { required: true,validator:isPrice,trigger:['blur','change'] } ]
      },
      rulesAttribute:{
        attribute:[{required:true, message: '商品参数名称不能为空', trigger: 'blur'}],
        value:[{required:true, message: '商品参数值不能为空', trigger: 'blur'}],
      }
    }
  },
  computed: {
    // retailPrice:function(){
    //   this.goods.retailPrice=Number(this.goods.counterPrice)+
    //           Number(this.goods.processCharge);
    //   if(isNaN(this.goods.retailPrice)){
    //     return 0;
    //   }
    //   return this.goods.retailPrice;
    // },
    headers() {
      return {
        // 'X-Dts-Admin-Token': getToken()
      }
    },
  },
  created() {
    this.$store.commit('changeMenuType', 'SELL');
    this.getAllCategory();
    this.getAllRegion();
    this.customize= []
    this.input=[]
    this.specifications = []
    this.products = []
    if (this.$route.query.id == null) {
      this.isAdd=true;
      return
    }
    const goodsId = this.$route.query.id
    this.isAdd=false;
    goodsDetail({goodsId}).then(response => {
      this.goods = response.data.goods
      this.specifications = response.data.specifications
      this.products = response.data.products
      this.attributes = response.data.attributes
      this.categoryIds = response.data.categoryIds
      this.regionIds = response.data.regionIds

      const _this=this
      response.data.customize.forEach(function (item) {
        _this.customize.push({title:item})
        _this.input.push({title:item,data:'',readonly:true})
      })

      for (let i = 0; i <this.products.length ; i++) {
        for(let j in this.products[i].specifications){
          this.products[i]['value'+j] = this.products[i].specifications[j]
        }
      }

      this.galleryFileList = []
      for (let i = 0; i < this.goods.gallery.length; i++) {
        this.galleryFileList.push({
          url: this.goods.gallery[i]
        })
      }
      this.tableShow=true
      // const keywords = response.data.goods.keywords
      // if (keywords !== null) {
      //   this.keywords = keywords.split(',')
      // }
      this.checkboxChange()
    })
  },
  methods: {
    uploadPicUrl: function(response) {
      this.goods.picUrl = response.data.url
    },
    uploadOverrun: function() {
      this.$message({
        type: 'error',
        message: '上传文件个数超出限制!最多上传5张图片!'
      })
    },
    handleGalleryUrl(response, file, fileList) {
      if (response.errno === 0) {
        this.goods.gallery.push(response.data.url)
      }
    },
    handleRemove: function(file, fileList) {
      for (var i = 0; i < this.goods.gallery.length; i++) {
        // 这里存在两种情况
        // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.data.url
        //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
        // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
        var url
        if (file.response === undefined) {
          url = file.url
        } else {
          url = file.response.data.url
        }

        if (this.goods.gallery[i] === url) {
          this.goods.gallery.splice(i, 1)
        }
      }
    },
    handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1)
      this.goods.keywords = this.keywords.toString()
    },
    handleInputConfirm() {
      const newKeyword = this.newKeyword
      if (newKeyword) {
        this.keywords.push(newKeyword)
        this.goods.keywords = this.keywords.toString()
      }
      this.newKeywordVisible = false
      this.newKeyword = ''
    },
    showInput() {
      this.newKeywordVisible = true
      this.$nextTick(_ => {
        this.$refs.newKeywordInput.$refs.input.focus()
      })
    },
    //分类和地区
    handleCategoryChange(value) {
      this.goods.categoryId = value[value.length - 1]
    },
    handleRegionChange(value) {
      this.goods.regionId = value[value.length - 1]
    },

    specChanged: function(label) {
      if (label === false) {
        this.specifications = [{ specification: '规格', value: '标准', picUrl: '' }]
        this.products = [{ id: 0, specifications: [''], price: 0.00, number: 0, url: '' }]
      } else {
        if(this.isAdd){
          this.specifications = []
          this.products = []
        }
      }
    },
    //显示规格添加表单
    handleSpecificationShow() {
      // this.input = this.cache
      this.specForm = { specifications: [], price: '', url: '',number:'' }
      this.specVisiable = true
      // if(this.specifications.length<=0){
      //   this.specVisiable = true
      //   return
      // }
    //  this.$notify.info({
    //    title:"提示",
    //    message:"已有规格"
    //  })
    },
    //商品规格删除
    handleSpecificationDelete(row) {
      const index = this.products.indexOf(row)
      //删除规格信息时，当商品货物数组长度为1时直接清空
      if(this.products.length === 1){
        this.products=[]
        this.specifications=[];
        this.input=[];
        this.customize=[]
        this.tableShow=false;
        return
      }
      //删除规格
      for (let i = 0; i < this.products[index].specifications.length; i++) {
        for (let j = 0; j < this.specifications.length; j++) {
          if(this.products[index].specifications[i]===this.specifications[j].value){
            if(this.customize[i].title===this.specifications[j].specification){
              this.specifications.splice(j,1)
              break
            }
          }
        }
      }

      let col = []
      this.customize=[]
      this.input=[]
      const _this=this

      this.specifications.filter(function (ele,i,self) {
        col.push(ele.specification)
        return ele.specification;
      })
      col=col.filter(function (ele,i,self) {
        const result = self.indexOf(ele) === i;
        if(result){
          _this.customize.push({title:ele})
          _this.input.push({title:ele,data:'',readonly:true})
        }
        return result
      })


      this.products.splice(index, 1)
      // this.specToProduct()
    },
    specToProduct() {
      //判断规格数组长度
      if (this.specifications.length === 0) {
        return
      }
      // 根据specifications创建临时规格列表
      var specValues = []
      var spec = this.specifications[0].specification
      var values = []
      values.push(0)

      for (var i = 1; i < this.specifications.length; i++) {
        const aspec = this.specifications[i].specification

        if (aspec === spec) {
          values.push(i)
        } else {
          specValues.push(values)
          spec = aspec
          values = []
          values.push(i)
        }
      }
      specValues.push(values)

      // 根据临时规格列表生产货品规格
      // 算法基于 https://blog.csdn.net/tyhj_sf/article/details/53893125
      var productsIndex = 0
      var products = []
      var combination = []
      var n = specValues.length
      for (var s = 0; s < n; s++) {
        combination[s] = 0
      }
      var index = 0
      var isContinue = false
      do {
        var specifications = []
        for (var x = 0; x < n; x++) {
          var z = specValues[x][combination[x]]
          specifications.push(this.specifications[z].value)
        }
        products[productsIndex] = { id: productsIndex, specifications: specifications, price: 0.00, number: 0, url: '' }
        productsIndex++

        index++
        combination[n - 1] = index
        for (var j = n - 1; j >= 0; j--) {
          if (combination[j] >= specValues[j].length) {
            combination[j] = 0
            index = 0
            if (j - 1 >= 0) {
              combination[j - 1] = combination[j - 1] + 1
            }
          }
        }
        isContinue = false
        for (var p = 0; p < n; p++) {
          if (combination[p] !== 0) {
            isContinue = true
          }
        }
      } while (isContinue)

      this.products = products
    },
    //图片上传
    uploadSpecPicUrl: function(response) {
      this.specForm.url = response.data.url
    },
    isBlank(val){
      if(val==undefined || val==null || val==""){
        return true
      }else{
        return false
      }
    },
    // 确定添加一类新的规格
    handleSpecificationAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return
        }
        //判断是否有规格
        if(this.inspection()){
          // this.$message({
          //   showClose: true,
          //   message: '请添加规格值',
          //   type:'info'
          // });
          return
        }

        //防止用户误触导致最后多出一列空
        var item=this.input[this.input.length-1];
        if(this.isBlank(item.title) && this.isBlank(item.data)){
          if(this.input.length==1){
            // this.$message({
            //   showClose: true,
            //   message: '请填写规格',
            //   type:'error'
            // });
            this.input.splice(this.input.length-1,1)
            return;
          }
          this.input.splice(this.input.length-1,1)
        }else{

        }

        //判断列重复
        let title=[]
        for (let i = 0; i < this.input.length; i++) {
          var item=this.input[i];
          if(title.indexOf(item.title)<0){
            title.push(item.title)
          }else {
            this.$message({
              message: '第'+(i+1)+'列的列名重复',
              type: 'warning'
            });
            return
          }
        }

        const _this = this;
        //将表格的数据赋值到product
        let product=this.specForm
        product.specifications=[]

        // this.input[this.input.length-1]['readonly']=true


        //规格复制
        this.input.forEach(function (item,index) {
          item['readonly']=true
          let specification={specification: item.title, value: item.data}
          _this.specifications.push(specification)
          product.specifications.push(item.data)
        })

        let data = this.formatData() , value = {};
        for(let i in data){
          value['value'+i] = data[i].data
        }
        product = Object.assign(product,value)
        if(!isNaN(this.goods.processCharge)){
          product.price=Number(product.price)+Number(this.goods.processCharge)
        }
        this.products.push(product)
        // var index = this.specifications.length - 1
        // for (var i = 0; i < this.specifications.length; i++) {
        //   const v = this.specifications[i]
        //   debugger
        //   if (v.specification === this.specForm.specification) {
        //     index = i
        //   }
        // }
        //
        // console.log(this.specifications.splice(index + 1, 0, this.specForm))
        // console.log(this.specifications)
        // 将自定义规划数据和其合并


        // data.map((item,index)=>{
        //   value[`value${index}`] = item.data
        // })

        // console.log(value)
        // debugger
        // this.specifications[ind] = Object.assign(this.specifications[ind],data,value)
        // console.log(this.specifications)
        // debugger

        this.specVisiable = false
        // this.specToProduct()

        this.tableShow = true
      })
    },
    //货品价格
    handleProductShow(row) {
      this.productForm = Object.assign({}, row)
      this.productVisiable = true
    },
    //文件上传
    uploadProductUrl: function(response) {
      this.productForm.url = response.data.url
    },
    //货品价格
    handleProductEdit() {
      for (var i = 0; i < this.products.length; i++) {
        const v = this.products[i]
        if (v.id === this.productForm.id) {
          this.products.splice(i, 1, this.productForm)
          break
        }
      }
      this.productVisiable = false
    },
    //显示商品参数添加的表单
    handleAttributeShow() {
      if (this.attributes.length >= 10) {
        this.$notify.info({
          title: "提示",
          message: "商品参数不能超过10个",
          duration: 1500,
        })
      }else {
        this.attributeForm = {}
        this.attributeVisiable = true
      }
    },
    //商品参数删除
    handleAttributeDelete(row) {
      const index = this.attributes.indexOf(row)
      this.attributes.splice(index, 1)
    },
    handleAttributeAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return
        }
        if (this.attributes.length >= 10) {
          this.$notify.info({
            title: "提示",
            message: "商品参数不能超过10个",
            duration: 1500,
          })
        } else {
          this.attributes.unshift(this.attributeForm)
          this.attributeVisiable = false
        }
      })
    },
    handleCancel: function() {
      this.$router.push({ path: '/User/ProductList' })
    },
    //获取一二级分类
    getAllCategory() {
      categoryAll().then(res => {
        this.categoryList = res.data.map(parent => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map(child => {
              return {
                value: child.id,
                label: child.name
              }
            })
          }
        });
      });
    },
    //获取一二级地区
    getAllRegion() {
      regionProvinceAndCity().then(res => {
        this.regionList = res.data.map(parent => {
          return {
            value: parent.id,
            label: parent.name,
            children: parent.child.map(child => {
              return {
                value: child.id,
                label: child.name
              }
            })
          }
        });
      });
    },
    //商品修改时的判断
    verify(){
      if(this.specifications.length<=0){
        if(this.goods.inventory===null || this.goods.inventory==='')return false;
      }
      return true
    },
    //增加商品
    handlePublish: function(formName) {
      this.$refs[formName].validate((valid) => {
        if(!valid){
          return
        }
        // if(!this.verify())return;
        const finalGoods = {
          goods: this.goods,
          specifications: this.specifications,
          products: this.products,
          attributes: this.attributes
        }
        addGoods(finalGoods).then(response => {
          if(response.errno===0){
            this.$router.push({ path: '/User/ProductList' })
          }
        }).catch(response => {
          MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
            confirmButtonText: '确定',
            type: 'error'
          })
        })
      })
    },
    //修改商品
    handleUpdateGoods(formName){
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return
        }
        // if(!this.verify()) return;
        const finalGoods = {
          goods: this.goods,
          specifications: this.specifications,
          products: this.products,
          attributes: this.attributes
        }
        modifyGoods(finalGoods).then(response => {
          if(response.errno!=0){
            return;
          }
          this.$notify.success({
            title: '成功',
            message: '修改成功'
          })
          this.$router.push({ path: '/User/ProductList' })
        }).catch(response => {
          MessageBox.alert('业务错误：' + response.data.errmsg, '警告', {
            confirmButtonText: '确定',
            type: 'error'
          })
        })
      })
    },
    // 添加新的规格
    addInput(){
      let inputData = this.input;

      if(inputData.length < 5){
        if(inputData.length === 0 || (inputData[inputData.length - 1].title !== '' && inputData[inputData.length - 1].data !== '')){
          inputData.push({data:'',title:''}
          )
        } else {
          this.$message({
            showClose: true,
            message: '请先填写完上一条规格后再添加新的'
          });
        }
      }else {
        this.$message({
          showClose: true,
          message: '最多只能添加五条规格'
        });
      }
    },

    // 监测新增的规格是否填写了
    inspection(){
      let inputData = this.input
      if(inputData.length == 0){
        this.$message({
          showClose: true,
          message: '请填写规格',
          type:'error'
        });
        return true;
      }
      for(let item in inputData) {
        if(inputData[item].title !== '' && inputData[item].data == ''){
          this.$message({
            showClose: true,
            message: '还有自定义规格值未填写',
            type:'error'
          });
          return true
        }
      }
      return false
    },

    // 将新增的规格合并成表格所需
    formatData(){
      let inputData = this.input ,  data = {};
      // this.cache = inputData
      // this.customize=[]
      for(let i in inputData){
        if(inputData[i].title !== '' && inputData[i].data !== '') {
          // data['data' + i] = inputData[i]
          data[i] = inputData[i]
        }
      }
      this.customize=inputData
      return data
    },
    //删除规格列时同时删除其他
    delCol(items,index){
      this.input.splice(index,1);
      this.customize.splice(index,1)
      //移除specifications的数据
      for (let i = 0; i < this.specifications.length; i++) {
        if(items.title===this.specifications[i].specification){
          this.specifications.splice(i,1)
        }
      }
      //删除products中的数据
      for (let j = 0; j < this.products.length; j++) {
        this.products[j].specifications.splice(index,1)
        delete this.products[j]['value'+index]
      }
    },
    //南海灵通铝价获取
    checkboxChange(){
      if(this.goods.isLvdingPrice){
        newestLvdingPrice().then(res=>{
          if(res.errno===0){
            this.lvDingInfo=res.data
            this.goods.counterPrice=res.data.taxPrice;
            this.goods.retailPrice=isNaN(this.goods.processCharge)?
                    res.data.taxPrice:this.goods.processCharge+res.data.taxPrice
          }else{
            this.goods.isLvdingPrice=false
          }
        })
      }else{
        this.goods.counterPrice=null;
        this.goods.retailPrice=isNaN(this.goods.processCharge)?0:this.goods.processCharge
      }
    },

    emptyThePrice(){
      if(this.goods.retailPrice!=
              Number(this.goods.processCharge)+Number(this.goods.counterPrice)){
        this.goods.counterPrice=null
      }
    }
  },
  watch:{
    'goods.counterPrice'(newValue, oldValue) {
      this.marking2 += 1
      if (this.marking2 > 1 || this.isAdd) {
        if (isNaN(newValue)) {
          return;
        }
        if (newValue == null && this.goods.processCharge!=null && !isNaN(this.goods.processCharge)) {
          this.goods.retailPrice = Number(this.goods.processCharge)
          return;
        }

        if (!isNaN(this.goods.processCharge)) {
          this.goods.retailPrice = Number(newValue) +
                  Number(this.goods.processCharge)
          return;
        }
        this.goods.retailPrice = Number(newValue)
      }
    },
    'goods.processCharge'(newValue, oldValue) {//加工费
      this.marking += 1
      if (this.marking > 1 || this.isAdd) {
        if (isNaN(newValue)) {
          return;
        }
        //修改规格内的值
        let num = 0
        if (!isNaN(oldValue)) {
          num = Number(newValue) - Number(oldValue)
        } else {
          num = Number(newValue)
        }
        this.products.forEach(product => {
          product.price = Number(num) + Number(product.price);
        })

        if(this.goods.counterPrice==null || isNaN(this.goods.counterPrice)){
          return;
        }

        if (!isNaN(this.goods.counterPrice)) {
          this.goods.retailPrice = Number(newValue) +
                  Number(this.goods.counterPrice)
        }
      }
      // this.goods.retailPrice=Number(newValue)
    },
  }
  // watch:{
  //   specifications: function(oldV,newV){
  //     let data = {}
  //     function checking(e,i){
  //       if(e[`data${i}value`])return true
  //       return false
  //    }
  //     newV.forEach(e => {
  //       data.data0.push(checking(e,0))
  //       data.data1.push(checking(e,1))
  //       data.data2.push(checking(e,2))
  //       data.data3.push(checking(e,3))
  //       data.data4.push(checking(e,4))
  //     });
  //     data.data0.forEach(i=>{
  //       if(data)
  //     })
  //   }
  // }
}
</script>

<style lang="less" scoped>
  /deep/.el-card.is-always-shadow,/deep/ .el-card.is-hover-shadow:focus,/deep/ .el-card.is-hover-shadow:hove{
    box-shadow: none;
  }
  /deep/.el-card{
    border:none
  }
  .op-container {
    text-align: center;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px;
  }
</style>

<style>
  .el-card {
    margin-bottom: 10px;
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .input-new-keyword {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .avatar-uploader .el-upload {
    width: 145px;
    height: 145px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #20a0ff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }

  .avatar {
    width: 145px;
    height: 145px;
    display: block;
  }
</style>

